import React from 'react'
import { graphql } from 'gatsby'
import { withIntl } from 'i18n'

import Layout from '../components/Layout'
import SEO from '../components/Layout/SEO'
import ContentBlock from '../components/ContentBlock'

const CookiePolicyPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <Layout>
      <SEO title={frontmatter.title} />

      <ContentBlock className="basic">
        <div className="container">
          <div
            className="markdown-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </ContentBlock>
    </Layout>
  )
}

export default withIntl(CookiePolicyPage)

export const CookiePolicyQuery = graphql`
  query CookiePolicyQuery($locale: String) {
    markdownRemark(
      fileAbsolutePath: { regex: "/cookie-policy/" }
      frontmatter: { locale: { eq: $locale } }
    ) {
      html
      frontmatter {
        title
      }
    }
  }
`
